import React from 'react';
import ReactDOM from 'react-dom/client';
//import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { withRouter } from 'psCMS-common';
//import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Landing from './views/landing/index';
import Main from './views/main/index';
import './i18n/config';
import i18n from "i18next";
//import 'bootstrap/dist/css/bootstrap.min.css'; //the default overrides the psCMS style so this is turned off
import 'psCMS-common/src/css/base.scss';
import 'psCMS-common/src/css/base-darkmode.scss';
import 'psCMS-common/src/css/responsive.scss';
import './css/bootstrap.scss';
import './css/bootstrap-darkmode.scss';
import './css/landing.scss';
import './css/links.scss';
import './css/responsive.scss';


export class PSCMS {
	constructor() {
		this.softwareVersion = 'v2.0.0';
		this.name = 'psCMS';
	}
}



let DefaultLanguageRedirector = withRouter(class Redirector extends React.Component {

	render() {
		return (
			<Navigate to={"/en"+this.props.router.location.pathname} replace />
		)
	}

})


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	//<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path="/">
					<Route index element={<Landing i18n={i18n} />} />
					<Route path="/c/*" element={<DefaultLanguageRedirector />} />
					<Route path="/brewing/*" element={<DefaultLanguageRedirector />} />
					<Route path=":lang/">
						<Route index element={<Landing i18n={i18n}/>} />
						<Route path='*' element={<Main i18n={i18n}/>} />
					</Route>
				</Route>
			</Routes>
		</BrowserRouter>
	//</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
